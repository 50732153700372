export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは11月より%d点上がりました。慌ただしい12月も良い生活習慣が増やせているようですね。その調子です！<br>\n1月のテーマは、<b>❝前向きなココロとカラダを手に入れる​❞</b>​   です。新年のスタートを心地よく切るために、取り入れたい習慣をご紹介。＆wellを使いながら、2024年も健康的な1年を過ごしましょう！\n\n",
      "down": "生活習慣スコアは11月より%d点下がりました。スコアが低い項目は、月ごとに変わりますか？それともいつも同じですか？今の自分の状況を知ることも大切です。<br>\n1月のテーマは、<b>❝前向きなココロとカラダを手に入れる​❞</b>​   です。新年のスタートを心地よく切るために、取り入れたい習慣をご紹介。＆wellを使いながら、2024年も健康的な1年を過ごしましょう！\n\n",
      "equal100": "生活習慣スコアは11月に引き続き%満点%です。大変素晴らしいです！<br>\n1月のテーマは、<b>❝前向きなココロとカラダを手に入れる​❞​</b>   です。新年のスタートを心地よく切るために、取り入れたい習慣をご紹介。＆wellを使いながら、2024年も健康的な1年を過ごしましょう！\n\n",
      "equal": "生活習慣スコアは11月と同じ点数です。<br>\n1月のテーマは、<b>❝前向きなココロとカラダを手に入れる​❞​</b>   です。新年のスタートを心地よく切るために、取り入れたい習慣をご紹介。＆wellを使いながら、2024年も健康的な1年を過ごしましょう！\n\n"
    },
    "steps": {
      "up": "歩数スコアは11月より%d点上がりました。<br>\nウォーキングやジョギングなど一定のリズムで同じ動きを繰り返す運動は、幸せホルモン（セロトニン）の分泌を増やします。ストレス解消や集中力UPに！\n\n",
      "down": "歩数スコアは11月より%d点下がりました。<br>\nウォーキングやジョギングなど一定のリズムで同じ動きを繰り返す運動は、幸せホルモン（セロトニン）の分泌を増やします。ストレス解消や集中力UPに！\n\n",
      "equal": "歩数スコアは11月と同じ点数です。<br>\nウォーキングやジョギングなど一定のリズムで同じ動きを繰り返す運動は、幸せホルモン（セロトニン）の分泌を増やします。ストレス解消や集中力UPに！\n\n"
    },
    "exercise": {
      "up": "運動のスコアは11月より%d点上がりました。<br>\n夏よりも寒い冬の方が基礎代謝が高く、同じ運動量でも消費カロリーが多くなります。つまり冬の方が、効率よく脂肪を燃焼させることができます！\n\n",
      "down": "運動のスコアは11月より%d点下がりました。<br>\n夏よりも寒い冬の方が基礎代謝が高く、同じ運動量でも消費カロリーが多くなります。つまり冬の方が、効率よく脂肪を燃焼させることができます！\n\n",
      "equal": "運動のスコアは11月と同じ点数です。<br>\n夏よりも寒い冬の方が基礎代謝が高く、同じ運動量でも消費カロリーが多くなります。つまり冬の方が、効率よく脂肪を燃焼させることができます！\n\n"
    },
    "meal": {
      "up": "食事のスコアは11月より%d点上がりました。<br>\n忘年会が多い時期も良い食生活ができていますね。今月は新年会が多いかもしれませんが、その調子で良い習慣を続けてください。\n\n",
      "down": "食事のスコアは11月より%d点下がりました。<br>\n食べ過ぎて胃もたれしてしまった時は、食物繊維の多い食品（きのこやこんにゃく、海藻類など）を避けて、胃を休ませてあげてください。\n\n",
      "equal": "食事のスコアは11月と同じ点数です。<br>\n食べ過ぎて胃もたれしてしまった時は、食物繊維の多い食品（きのこやこんにゃく、海藻類など）を避けて、胃を休ませてあげてください。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは11月より%d点上がりました。<br>\n11月よりも飲み過ぎた日が少なくなっています。飲む機会が多い時期に素晴らしいですね。寝起きがスッキリしたなど、何か変化はありましたか？\n\n",
      "down": "飲酒のスコアは11月より%d点下がりました。<br>\n11月よりも飲み過ぎた日が多かったようです。<a href=\"https://go.andwell.jp/library/363bf3a6-d744-11ed-b977-0ab1e6a5e015\">こちらのコラム</a>では、飲んだ後に〆料理が食べたくなる理由とオススメの〆料理をご紹介！\n\n",
      "equal": "飲酒のスコアは11月と同じ点数です。<br>\n<a href=\"https://go.andwell.jp/library/363bf3a6-d744-11ed-b977-0ab1e6a5e015\">こちらのコラム</a>では、飲んだ後に〆料理が食べたくなる理由とオススメの〆料理をご紹介！\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは11月より%d点上がりました。<br>\nウイルスなどの病原菌を攻撃するNK細胞の活性が下がる時間帯<b>（夜10時～深夜2時）</b>にあわせて、身体を休めると免疫力アップにつながります。\n\n",
      "down": "睡眠のスコアは11月より%d点下がりました。<br>\nウイルスなどの病原菌を攻撃するNK細胞の活性が下がる時間帯<b>（夜10時～深夜2時）</b>にあわせて、身体を休めると免疫力アップにつながります。\n\n",
      "equal": "睡眠のスコアは11月と同じ点数です。<br>\nウイルスなどの病原菌を攻撃するNK細胞の活性が下がる時間帯<b>（夜10時～深夜2時）</b>にあわせて、身体を休めると免疫力アップにつながります。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは11月より%d点上がりました。<br>\nストレスは溜め込まずに<a href=\"https://go.andwell.jp/library/dff57670-9b0d-11ee-a8f1-0a74c02d2ee3\">こちらのコラム</a>を見ながら、こまめに解消していくようにしましょう。\n\n",
      "down": "ストレスのスコアは11月より%d点下がりました。<br>\nストレスは溜め込まずに<a href=\"https://go.andwell.jp/library/dff57670-9b0d-11ee-a8f1-0a74c02d2ee3\">こちらのコラム</a>を見ながら、こまめに解消していくようにしましょう。\n\n",
      "equal": "ストレスのスコアは11月と同じ点数です。<br>\nストレスは溜め込まずに<a href=\"https://go.andwell.jp/library/dff57670-9b0d-11ee-a8f1-0a74c02d2ee3\">こちらのコラム</a>を見ながら、こまめに解消していくようにしましょう。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your Lifestyle Score has gone up by ○ points sinceNovember. It looks like you are improving your lifestyle habits in the hectic month of December. Keep it up!\nYour Lifestyle Score has dropped by %d points since November. Do the low scoring items change from month to month? Or are they always the same? It is important to know your current situation.<br>\nThe theme for January is <b>\"To have a positive mind and body.\"</b> Here are some habits you can adopt to start the new year off on the right foot. Let's have a healthy year in 2024 with &well!\n\n",
      "down":"Your Lifestyle Score has dropped by %d points since November. Do the low scoring items change from month to month? Or are they always the same? It is important to know your current situation.<br>\nThe theme for January is <b>\"To have a positive mind and body.\"</b> Here are some habits you can adopt to start the new year off on the right foot. Let's have a healthy year in 2024 with &well!\n\n",
      "equal100": "Your Lifestyle score is perfect, just as it was in November. <br>\nThe theme for January is <b>\"To have a positive mind and body.\"</b> Here are some habits you can adopt to start the new year off on the right foot. Let's have a healthy year in 2024 with &well!\n\n",
      "equal": "Your Lifestyle score is the same as it was in November.<br>\nThe theme for January is <b>\"To have a positive mind and body.\"</b> Here are some habits you can adopt to start the new year off on the right foot. Let's have a healthy year in 2024 with &well!\n\n",
    },
    "steps": {
      "up": "Your steps score has gone up %d pts since November.<br>\nExercises that involve repeating the same movement in a steady rhythm, such as walking and jogging, increase the secretion of the happy hormone (serotonin). It is a stress reliever and improves concentration!\n\n",
      "down": "Your steps score has gone down %d pts since November. <br>\nExercises that involve repeating the same movement in a steady rhythm, such as walking and jogging, increase the secretion of the happy hormone (serotonin). It is a stress reliever and improves concentration!\n\n",
      "equal": "Your steps score is the same as November. <br>\nExercises that involve repeating the same movement in a steady rhythm, such as walking and jogging, increase the secretion of the happy hormone (serotonin). It is a stress reliever and improves concentration!\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d pts since November.<br>\nThe basal metabolism becomes higher in winter, as it is colder than in summer, and the same amount of exercise burns more calories. In other words, you can burn fat more efficiently in winter!\n\n",
      "down": "Your exercise score has gone down %d pts since November.<br>\nThe basal metabolism becomes higher in winter, as it is colder than in summer, and the same amount of exercise burns more calories. In other words, you can burn fat more efficiently in winter!\n\n",
      "equal": "Your exercise score is the same as November.<br>\nThe basal metabolism becomes higher in winter, as it is colder than in summer, and the same amount of exercise burns more calories. In other words, you can burn fat more efficiently in winter!\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d pts since November.<br>\nYou have a good diet during a time with many year-end parties. There may be a New Year's party this month, but keep up the good habits.\n\n",
      "down": "Your food score has gone down %d pts since November.<br>\nIf you eat too much and have a stomach ache, avoid foods that are high in fiber (such as mushrooms, konjac, and seaweed) and to give your stomach a rest.\n\n",
      "equal": "Your food score is the same as November.<br>\nIf you eat too much and have a stomach ache, avoid foods that are high in fiber (such as mushrooms, konjac, and seaweed) and to give your stomach a rest.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d pts since November.<br>\nYou have fewer days of drinking too much than in November. That's great when there are many opportunities to drink. Did you experience any physical changes, such as a refreshing wake up?\n\n",
      "down": "Your alcohol score has gone down %d pts since November.<br>\nIt seems that you had more days of excessive drinking than in November. In <a href=\"https://go.andwell.jp/library/363bf3a6-d744-11ed-b977-0ab1e6a5e015\">this column</a>, we explain why you might feel the desire to have a final dish after drinking, and give you our recommendations for dishes to finish off a meal!\n\n",
      "equal": "Your alcohol score is the same as November.<br>\nIt seems that you had more days of excessive drinking than in November. In <a href=\"https://go.andwell.jp/library/363bf3a6-d744-11ed-b977-0ab1e6a5e015\">this column</a>, we explain why you might feel the desire to have a final dish after drinking, and give you our recommendations for dishes to finish off a meal!\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d pts since November.<br>\nResting the body during the time when the activity of NK cells, which attack pathogens such as viruses, is lowered <b>(10:00 p.m. to 2:00 a.m.)</b> will help boost the immune system.\n\n",
      "down": "Your sleep score has gone down %d pts since November.<br>\nResting the body during the time when the activity of NK cells, which attack pathogens such as viruses, is lowered <b>(10:00 p.m. to 2:00 a.m.)</b> will help boost the immune system.\n\n",
      "equal": "Your sleep score is the same as November.<br>\nResting the body during the time when the activity of NK cells, which attack pathogens such as viruses, is lowered <b>(10:00 p.m. to 2:00 a.m.)</b> will help boost the immune system.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d pts since November.<br>\nYou are having more days that you feel good. Don't let stress build up, and try to relieve your stress regularly by reading <a href=\"https://go.andwell.jp/library/dff57670-9b0d-11ee-a8f1-0a74c02d2ee3\">this column</a>.\n\n",
      "down": "Your stress score has gone down %d pts since November.<br>\nDon't let stress build up, and try to relieve your stress regularly by reading <a href=\"https://go.andwell.jp/library/dff57670-9b0d-11ee-a8f1-0a74c02d2ee3\">this column</a>.\n\n",
      "equal": "Your stress score is the same as November.<br>\nDon't let stress build up, and try to relieve your stress regularly by reading <a href=\"https://go.andwell.jp/library/dff57670-9b0d-11ee-a8f1-0a74c02d2ee3\">this column</a>.\n\n"
    }
  }
}